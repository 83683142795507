.prev,
.next {
    cursor: pointer;
    position: absolute;

    width: auto;
    margin-top: 25px;
    color: #fff !important;
    font-weight: bold;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

@media (min-width: 320px) {
    .prev,
    .next {
        margin-top: 155px;
    }
    .next-right {
        margin-left: 250px
    }
    .MuiButton-containedPrimary{
        font-size: 12px !important;
    }
}
@media (min-width: 360px) {
    .next-right {
        margin-left: 290px
    }
  
}
@media (min-width: 375px) {
    .next-right {
        margin-left: 310px
    }
    .MuiButton-containedPrimary{
        font-size: 14px !important;
    }
}
@media (min-width: 425px) {
    .next-right {
        margin-left: 360px
    }
}

@media (min-width: 768px) {

    .prev,
    .next {
        position: fixed;
        margin-top: 45px;
    }

    .next-right {
        margin-left: 475px
    }

    .totLaporan {
        padding-top: 10px;
        font-size: 16px;
    }
    .promptSize{
        width:500px !important;
        height:100px !important;
    }
}

@media (min-width: 992px) {

    .prev,
    .next {
        position: fixed;
        margin-top: 45px;
    }

    .next-right {
        margin-left: 578px
    }

    .totLaporan {
        font-size: 19px;
    }
}

@media (min-width: 1200px) {

    .prev,
    .next {
        position: fixed;
        margin-top: 45px;
    }
    .next-right {
        margin-left: 695px
    }
}
.activeList{
    background-color: #bfbcbc;
}